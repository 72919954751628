@charset "utf-8";
/* CSS Document */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
landmark,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: "Montserrat-Bold";
    font-weight: 600;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

:root {
    --primery-color: #f36c21;
    --black: #000000;
    --white: #ffffff;
    --gray: #7f7f7f;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html {
    scroll-behavior: smooth;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    font-size: 16px;
    color: #000;
    font-family: "Montserrat";
    font-weight: 300;
}

.wrapper {
    width: 100%;
}

/* global */
.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 22px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch input:checked+.slider {
    background-color: #2196f3;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
}

.switch .slider.round {
    border-radius: 50px;
}

.switch .slider.round:before {
    border-radius: 50%;
}

.button-white {
    width: 100%;
    height: 58px;
    background: #fff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 20px;
    font-size: 18px;
    line-height: 20px;
    color: #1b2b81;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
}

.button-white:hover {
    color: #1b2b81;
    text-decoration: none;
}

.button-white:after {
    width: 18px;
    height: 17px;
    background: url(../images/arrow-blue.webp) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -9px;
}

.button-white.no-arrow:after {
    display: none;
}

.button-blue {
    width: 100%;
    height: 58px;
    background: #1b2b81;
    border-radius: 16px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 2px 20px 0;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
}

.button-blue:after {
    width: 18px;
    height: 17px;
    background: url(../images/arrow-white.webp) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -9px;
}

.button-blue:hover {
    background-color: #7a126b;
    color: white;
    text-decoration: none;
}

.button-blue.no-arrow:after {
    display: none;
}

.button-gradient {
    font-family: "Montserrat";
    font-style: normal;
    width: 100%;
    height: 32px;
    background: #11184f;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 20px;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    letter-spacing: 0.75px;
    font-weight: 400;
    text-decoration: none;
}

.button-gradient1 {
    width: 234px;
    height: 47px;
    background-image: linear-gradient(to right, #24c6dc, #514a9d);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 20px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    letter-spacing: 0.75px;
    font-weight: 500;
    text-decoration: none;
    margin-top: 10%;
}

.button-gradient .disabled {
    width: 100%;
    height: 32px;
    background-image: linear-gradient(to right, #bdc0c0, #8b8b8d);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 20px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    letter-spacing: 0.75px;
    font-weight: 500;
    text-decoration: none;
}

.button-gradient1 .disabled {
    width: 100%;
    height: 32px;
    background-image: linear-gradient(to right, #bdc0c0, #8b8b8d);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 20px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    letter-spacing: 0.75px;
    font-weight: 500;
    text-decoration: none;
}

.button-gradient:hover {
    color: #fff;
    text-decoration: none;
    background: #1b2b81;
}

.button-gradient1:hover {
    color: #fff;
    text-decoration: none;
    background: #1b2b81;
}

.button-white-rd {
    width: 100%;
    height: 32px;
    background: #fff;
    border-radius: 20px;
    /*border:2px solid #D6D8E7; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 20px;
    font-size: 12px;
    line-height: 14px;
    color: #1b2b81;
    letter-spacing: 0.75px;
    font-weight: 500;
    text-decoration: none;
}

.button-white-rd:hover {
    background-image: linear-gradient(to right, #24c6dc, #514a9d);
    color: #fff;
    text-decoration: none;
}

.button-blue-rd {
    width: 100%;
    height: 32px;
    background: #7a126b;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 20px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    letter-spacing: 0.75px;
    font-weight: 400;
    text-decoration: none;
    font-family: "Montserrat";
    font-style: normal;
}

.button-blue-rd:hover {
    background-image: linear-gradient(to right, #11184f, #11184f);
    color: #fff;
    text-decoration: none;
}

.share-but {
    width: 80px;
    height: 40px;
    background: #ebebeb;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 600;
}

.share-but:hover {
    text-decoration: none;
    color: black;
}

.view-button {
    width: 140px;
    height: 60px;
    background: #fff;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 600;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.5);
}

.view-button:hover {
    background-image: linear-gradient(to right, #7a126b, #7a126b);
    color: #fff;
    text-decoration: none;
}

.buy-online-but {
    display: block;
    font-size: 18px;
    line-height: 24px;
    background: transparent;
    font-weight: 600;
    color: #1b2b81;
    letter-spacing: 0.2px;
    position: relative;
    padding: 5px 15px;
    margin: 0 0 15px;
}

.buy-online-but:after {
    width: 24px;
    height: 23px;
    background: url(../images/cart-icon-blue.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -12px;
}

.buy-online-but:hover {
    text-decoration: none;
    color: #1b2b81;
}

.call-store-but {
    display: block;
    font-size: 18px;
    line-height: 24px;
    background: #7a126b;
    font-weight: 600;
    color: #fff;
    border-radius: 16px;
    letter-spacing: 0.2px;
    position: relative;
    padding: 16px 15px;
    margin: 0 0 8px;
}

.call-store-but:after {
    width: 23px;
    height: 23px;
    /* background: url(../images/forward_arrow_white.svg) no-repeat left top / 100%;*/
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -45%);
}

.call-store-but:hover {
    text-decoration: none;
    color: #fff;
}

.book-demo-but {
    display: block;
    font-size: 18px;
    line-height: 24px;
    background-image: linear-gradient(to right, #24c6dc, #514a9d);
    font-weight: 600;
    color: #fff;
    border-radius: 16px;
    letter-spacing: 0.2px;
    position: relative;
    padding: 16px 30px;
}

.input-bx {
    width: 100%;
    height: 42px;
    background: #fff;
    border-radius: 20px;
    box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.3);
    padding: 0 20px;
    position: relative;
}

.country-select-bx {
    width: 100%;
    height: 35px;
    background: #fff;
    border-radius: 0px 20px 20px 0px;
    box-shadow: inset 2px 2px 8px rgb(0 0 0 / 30%);
    padding: 0 20px;
    position: relative;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    min-width: 250px;
    max-width: 250px;
    text-align: left;
}

.language-select-bx {
    width: 100%;
    height: 35px;
    background: #fff;
    border-radius: 0px 20px 20px 0px;
    box-shadow: inset 2px 2px 8px rgb(0 0 0 / 30%);
    padding: 0 20px;
    position: relative;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    min-width: 150px;
    max-width: 150px;
    text-align: left;
}

@media (max-width: 475px) {
    .country-select-bx {
        min-width: 90%;
        max-width: 90%;
    }
}

.country-select-bx:focus {
    outline: none;
}

.language-select-bx:focus {
    outline: none;
}

.input-bx.open {
    height: auto;
}

.input-bx .text-field {
    width: 80%;
    height: 42px;
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #1b2b81;
    padding: 0;
}

.input-bx .text-field::placeholder {
    color: #1b2b81;
    opacity: 1;
}

.input-bx .text-field:-ms-input-placeholder {
    color: #1b2b81;
}

.input-bx .text-field::-ms-input-placeholder {
    color: #1b2b81;
}

.input-bx .search-button {
    width: 16px;
    height: 16px;
    background: url(../images/search-icon.png) no-repeat left top / 100%;
    border: none;
    outline: none;
    position: absolute;
    top: 12px;
    right: 20px;
    padding: 0;
}

.input-bx .search-dropdown {
    width: auto;
    clear: both;
    border-top: 1px solid #ebebeb;
    margin: 0 -20px;
    display: none;
}

.input-bx .search-dropdown .detect-location {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    font-weight: 500;
    padding: 10px 20px;
    text-decoration: none;
    clear: both;
}

.input-bx .search-dropdown .detect-location span {
    display: block;
    background: url(../images/detect-icon.png) no-repeat left center / 20px;
    padding: 0 0 0 35px;
}

.input-bx .search-dropdown .search-but-bx {
    width: 100%;
    padding: 20px 20px;
    border-top: 1px solid #ebebeb;
}

.input-bx .search-dropdown .search-but-bx .button-gradient {
    height: 48px;
    border-radius: 16px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}

.input-bx .search-dropdown .search-but-bx .button-gradient .disabled {
    background-image: #454545;
}

.input-bx .search-dropdown .search-but-bx .search-note {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #949494;
    font-weight: 500;
    text-align: center;
    padding: 8px 0 0 0;
}

.input-bx .search-dropdown .listing {
    width: 100%;
    float: left;
    display: block;
}

.input-bx .search-dropdown .listing li {
    width: 100%;
    float: left;
    display: block;
    border-bottom: 1px solid #ebebeb;
}

.input-bx .search-dropdown .listing li a {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 500;
    padding: 0 20px;
}

.input-bx .search-listing {
    padding: 0 0 10px;
}

.section-title {
    font-size: 18px;
    line-height: 22px;
    /* font-family: "Montserrat"; */
    font-family: "Montserrat-semiBold";
    font-style: normal;
    color: #1e0013;
    font-weight: 400;
    padding: 0 0 5px;
    margin: 0 0 20px 5px;
    text-align: left;
    position: relative;
}

.section-title:after {
    width: 170px;
    height: 5px;
    background: rgba(145, 145, 145, 0.2);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
}

.card-bx {
    width: 100%;
    max-width: 100%;
    min-height: 200px;
    background: #fff;
    border-radius: 20px;
    /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 3%); */
    padding: 25px;
    /* display: flex; */
}

.card-bx .product-title {
    font-size: 16px;
    line-height: 20px;
    color: #14142b;
    font-weight: 600;
    display: block;
    letter-spacing: 1px;
    padding: 0 0 10px;
}

.card-bx .product-desp {
    font-size: 12px;
    line-height: 14px;
    color: #14142b;
    display: block;
    letter-spacing: 0.75px;
    padding: 0;
}

.card-bx.gradient-bg {
    background-image: linear-gradient(to right, #24c6dc, #514a9d);
}

.card-bx.gradient-bg .product-title {
    color: #fff;
    padding: 0 0 5px;
}

.card-bx.gradient-bg .product-desp {
    color: #fff;
}

.card-bx.gradient-bg .product-img {
    margin: 0 0 5px;
    display: block;
}

.card-bx.min-height {
    min-height: 258px;
    max-height: 258px;
}

.store-get-assist-bx .card-bx .col-6 .product-img {
    max-width: 270px;
    display: block;
    float: right;
}

.store-get-assist-bx .card-bx.min-height {
    min-height: 316px;
}

.bx-wrapper {
    position: relative;
}

.bx-controls.bx-has-pager {
    width: 100%;
    padding: 10px 0 0 0;
}

.bx-controls.bx-has-pager .bx-pager {
    width: 100%;
    text-align: center;
}

.bx-controls.bx-has-pager .bx-pager .bx-pager-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
}

.bx-controls.bx-has-pager .bx-pager .bx-pager-item .bx-pager-link {
    width: 6px;
    height: 6px;
    background: #adadad;
    border-radius: 50%;
    display: block;
    text-decoration: none;
    font-size: 0px;
}

.bx-controls.bx-has-pager .bx-pager .bx-pager-item .bx-pager-link.active {
    width: 14px;
    border-radius: 10px;
}

.bx-controls .bx-controls-direction a {
    width: 30px;
    height: 30px;
    background: lightgray;
    border-radius: 50%;
    display: inline-block;
    text-decoration: none;
    font-size: 0;
    position: absolute;
    top: 50%;
    left: -15px;
    margin-top: 0;
}

.bx-controls .bx-controls-direction a.disabled {
    pointer-events: none;
    background: rgba(145, 145, 145, 0.2);
}

.bx-controls .bx-controls-direction a.bx-next {
    left: auto;
    right: -15px;
}

.bx-controls .bx-controls-direction a:before {
    width: 18px;
    height: 20px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNOC41OSAxNi41OUwxMy4xNyAxMiA4LjU5IDcuNDEgMTAgNmw2IDYtNiA2LTEuNDEtMS40MXoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -11px 0 0 -9px;
    transform: rotate(180deg);
}

.bx-controls .bx-controls-direction a.bx-next:before {
    left: auto;
    right: 50%;
    margin: -9px -9px 0 0;
    transform: none;
}

.store-view-offer-bx .bx-controls-direction a {
    margin-top: 0;
}

/* global */

/* header start */
header {
    width: 100%;
    /* background: #4d144a; */
    background-image: url("./../images/Rectangle.svg");
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
}

header .container {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 0;
}

header .logo {
    width: 42%;
    max-width: 174px;
    float: left;
    display: block;
    text-decoration: none;
    margin: 0 35px 0 0;
}

header .logo img {
    display: block;
}

header .nav-link-mob {
    float: left;
}

header .nav-link-mob ul {
    width: auto;
    float: left;
    display: block;
}

header .nav-link-mob ul li {
    float: left;
    display: block;
    padding: 0 10px 0 0;
    font-size: 12px;
    line-height: 16px;
    color: #939393;
    font-weight: 500;
}

header .nav-link-mob ul li:last-child {
    padding-right: 0;
}

header .nav-link-mob ul li a {
    color: #939393;
    text-decoration: none;
    display: inline-block;
    max-width: 75px;
    padding: 0 0 0 20px;
    position: relative;
}

header .nav-link-mob ul li a.offer-icon:before {
    width: 14px;
    height: 14px;
    background: url(../images/offer-icon.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
}

header .nav-link-mob ul li a.cart-icon {
    padding: 0 0 0 25px;
}

header .nav-link-mob ul li a.cart-icon:before {
    width: 18px;
    height: 17px;
    background: url(../images/cart-icon.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
}

header .main-nav-bx {
    width: auto;
}

header .main-nav-bx .nav-dot-icon {
    width: 5px;
    height: 23px;
    background: url(../images/menu-icon.png) no-repeat center top;
    display: inline-block;
    text-decoration: none;
    /* padding: 0 10px; */
    padding: 0 25px;
    margin: 11px 0 0 0;
}

header .main-nav-bx .main-nav {
    width: 100%;
}

header .main-nav-bx .main-nav ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

header .main-nav-bx .main-nav ul li {
    float: left;
    display: block;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    margin: 0 20px 0 0;
    overflow: hidden;
}

header .main-nav-bx .main-nav ul li * {
    transition: all 0.4s ease-in-out 0s;
}

header .main-nav-bx .main-nav ul li:last-child {
    margin-right: 0;
}

header .main-nav-bx .main-nav ul li a {
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 56px;
}

header .main-nav-bx .main-nav ul li.menu-item-1 .menu-txt {
    max-width: 62px;
    font-family: "Montserrat-SemiBold";
}

header .main-nav-bx .main-nav ul li.menu-item-2 .menu-txt {
    max-width: 110px;
}

header .main-nav-bx .main-nav ul li.menu-item-3 .menu-txt {
    max-width: 60px;
}

header .main-nav-bx .main-nav ul li.menu-item-4 .menu-txt {
    max-width: 50px;
}

header .main-nav-bx .main-nav ul li.menu-item-5 .menu-txt {
    max-width: 65px;
}

header .main-nav-bx .main-nav ul li.menu-item-6 .menu-txt {
    max-width: 70px;
}

header .main-nav-bx .main-nav ul li .menu-txt {
    overflow: hidden;
    width: 0px;
    position: relative;
    opacity: 01;
    left: -50;
}

header .main-nav-bx .main-nav ul li .menu-icon {
    display: inline-block;
    margin: 0 10px;
}

header .main-nav-bx .main-nav ul li .menu-icon.store-icon {
    /* width: 40px;
    height: 36px; */
    width: 36px;
    height: 34px;
    background: url(/assets/images/discover-stores-01.webp) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li .menu-icon.warranty-icon {
    width: 31px;
    height: 39px;
    margin-bottom: 1px;
    background: url(/assets/images/Warranty.svg) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li .menu-icon.globe-icon {
    /* width: 39px;
    height: 33px; */
    width: 55px;
    height: 50px;
    background: url(/assets/images/external-link.svg) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li .menu-icon.service-icon {
    width: 36px;
    height: 36px;
    background: url(../images/discover-service-icon.png) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li .menu-icon.pc-pal-icon {
    width: 36px;
    height: 36px;
    background: url(../images/pc_pal_logo.png) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li .menu-icon.pc-pal-icon1 {
    width: 36px;
    height: 36px;
    background: url(../images/pc_pal_logo1.png) no-repeat left top / 100%;
    transform: translateY(132%);
    position: absolute;
}

header .main-nav-bx .main-nav ul li .menu-icon.redeem-icon {
    width: 32px;
    height: 36px;
    background: url(../images/redeem-offer-icon.png) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li .menu-icon.buy-icon {
    width: 29px;
    height: 36px;
    background: url(../images/buy-online-icon.webp) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li .menu-icon.business-icon {
    width: 36px;
    height: 32px;
    background: url(../images/business-solution-icon.webp) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li .menu-icon.signup-icon {
    width: 33px;
    height: 32px;
    background: url(../images/sign-up-icon.png) no-repeat left top / 100%;
}

header .main-nav-bx .main-nav ul li:hover .menu-icon.store-icon {
    background-position: 0 0;
}

header .main-nav-bx .main-nav ul li:hover .menu-icon.service-icon {
    background-position: 0 -36px;
}

header .main-nav-bx .main-nav ul li:hover .menu-icon.pc-pal-icon {
    background-position: 0 -36px;
}

header .main-nav-bx .main-nav ul li:hover .menu-icon.pc-pal-icon1 {
    transform: translateY(0%);
}

header .main-nav-bx .main-nav ul li:hover .menu-icon.redeem-icon {
    background-position: 0 -36px;
}

header .main-nav-bx .main-nav ul li:hover .menu-icon.buy-icon {
    background-position: 0 -36px;
}

header .main-nav-bx .main-nav ul li:hover .menu-icon.business-icon {
    background-position: 0 -32px;
}

header .main-nav-bx .main-nav ul li:hover .menu-icon.signup-icon {
    background-position: 0 -32px;
}

header .main-nav-bx .main-nav ul li:hover .menu-txt,
header .main-nav-bx .main-nav ul li a.active .menu-txt,
header .main-nav-bx .main-nav ul li.active a .menu-txt {
    opacity: 1;
    left: 0;
    width: 110px;
}

header .tollfree-txt {
    margin-left: auto;
    display: inline-block;
    background: #4ac0e0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: 600;
    padding: 2px 20px;
    /* margin-right: 20px; */
}

/* header end */

/* content start */
.content {
    width: 100%;
    padding: 57px 0 0 0;
}

.store-service-bx {
    width: 100%;
    padding: 25px 0 20px 0;
}

.store-service-bx .tab-section {
    position: relative;
}

.tab-section .tab-details {
    display: none;
}

.tab-section .tab-details:first-child {
    display: block;
}

.store-service-bx .tab-section .tab-thumb {
    width: auto;
    position: absolute;
    top: 2px;
    right: 0px;
}

.store-service-bx .tab-section .tab-thumb ul {
    width: 100%;
    background: #4d144a;
    border-radius: 30px;
    float: left;
    display: block;
    padding: 3px;
}

.store-service-bx .tab-section .tab-thumb ul li {
    float: left;
    display: block;
}

.store-service-bx .tab-section .tab-thumb ul li a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    line-height: 37px;
    color: #fff;
    font-weight: 500;
    padding: 0 5px;
    border-radius: 30px;
    min-width: 68px;
    height: 36px;
    text-align: center;
}

.store-service-bx .tab-section .tab-thumb ul li.active a {
    background: #7a126b;
}

.location-tab-bx {
    width: 100%;
    padding-top: 15px;
}

.location-tab-bx .section-heading {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: #1b2b81;
    display: block;
    padding: 0 0 0 28px;
    text-transform: uppercase;
    max-width: 200px;
    position: relative;
    margin: 0 0 25px;
}

.location-tab-bx .section-heading .store-icon {
    width: 18px;
    height: 20px;
    background: url(../images/store-icon.png) no-repeat left top / 100%;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
}

.location-tab-bx .section-heading .service-icon {
    width: 18px;
    height: 20px;
    background: url(../images/service-icon.png) no-repeat left top / 100%;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
}

.location-tab-bx .location-bx {
    width: 100%;
    border-radius: 20px;
}

.location-tab-bx .location-bx.location-off-bx {
    background: #f0f0f0;
}

.location-tab-bx .location-bx.location-on-bx {
    background: #4d144a;
}

.location-tab-bx .location-bx .map-bx {
    width: 100%;
    min-height: 375px;
    text-align: center;
    padding: 25px;
    margin: 0 0 25px;
}

.location-tab-bx .location-bx.location-off-bx .map-bx {
    border-radius: 20px;
    background: url(../images/map-off.jpg) no-repeat center top / 100% 100%;
}

.location-tab-bx .location-bx.location-on-bx .map-bx {
    background: url(../images/listing_bg.webp) no-repeat center top / 100% 100%;
    background-size: cover;
    border-radius: 20px;
}

.location-tab-bx .location-bx .map-bx .button-white {
    max-width: 400px;
    margin: 0 auto;
}

.location-tab-bx .location-bx .search-change-loc-bx {
    max-width: 450px;
    margin: 0 auto;
}

.location-tab-bx .location-bx .map-bx .loc-off-txt {
    font-size: 14px;
    line-height: 16px;
    color: #1b2b81;
    font-weight: 500;
    display: block;
    padding: 0 0 20px;
}

.location-tab-bx .location-bx .map-bx .loc-marker {
    max-width: 160px;
    display: block;
    margin: 0 auto 20px;
}

.location-tab-bx .location-bx .map-bx .loc-off-facts {
    display: block;
    font-size: 18px;
    line-height: 20px;
    color: #1b2b81;
    font-weight: 500;
    padding: 0 0 20px;
}

.location-tab-bx .location-bx .map-bx .found-result-txt {
    font-size: 16px;
    line-height: 20px;
    color: #1a2b81;
    font-weight: 500;
    display: block;
    padding: 0 0 20px;
}

.location-tab-bx .location-bx .map-bx .current-location {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #fff;
    display: none;
}

.location-tab-bx .location-bx .map-bx .turn-on-location.curr-loc-on .current-location {
    display: block;
}

.search-change-loc-bx {
    width: 100%;
    padding: 0 25px 25px;
}

.section-bg-color {
    width: 100%;
    background: #e6e2e4;
}

.scroll-more {
    display: block;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #adadad;
    text-align: center;
    padding: 25px 0;
}

.scroll-more .arrow-tb-icon {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 16px;
    background: url(../images/arrow-tb.webp) no-repeat left top / 100%;
    margin: 0 0 0 7px;
    position: relative;
    top: -2px;
}

.view-offer-bx {
    width: 100%;
    padding: 0 0 30px;
}

.view-offer-bx .container {
    padding: 0;
}

.view-offer-bx .button-gradient,
.get-assist-bx .button-white-rd {
    max-width: 102px;
    position: absolute;
    bottom: 0;
    left: 15px;
}

.view-offer-bx .item {
    margin-right: 0;
    padding: 0 20px 7px 5px;
}

.get-assist-bx {
    width: 100%;
    padding: 0 0 30px;
}

.get-assist-bx .bx-viewport {
    min-height: 256px;
}

.get-assist-bx .container {
    padding: 0;
}

.get-assist-bx .item {
    padding: 0 20px 7px 5px;
}

.get-assist-bx .button-gradient,
.get-assist-bx .button-white-rd {
    max-width: 102px;
    position: absolute;
    bottom: 0;
    left: 15px;
}

.offer-gallery .full-image img {
    border-radius: 20px;
}

/* content end */

/* footer start */
footer {
    width: 100%;
    background: #f9f9f9;
}

footer .intel-img-bx {
    max-width: 290px;
    margin: 0 0 0 20px;
}

footer .copyright-txt {
    width: 100%;
    padding: 0 20px;
}

footer .copyright-txt p {
    font-size: 12px;
    line-height: 16px;
    color: #000;
}

/* footer end */

.turn-on-location {
    text-align: left;
    display: flex;
    padding: 0 0 30px;
    min-height: 62px;
}

.turn-on-location .turn-on-loc-inner {
    display: flex;
    width: 100%;
}

.turn-on-location .switch {
    margin-right: 15px;
}

.turn-on-location .turn-on-desp {
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    font-weight: 500;
}

.store-listing-screen {
    width: 100%;
    height: 100%;
    background: #4d144a;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 99;
    display: none;
}

.store-listing-screen .container {
    padding: 0;
}

.store-listing-screen .logo-vertical {
    position: absolute;
    top: 0;
    left: 0;
}

.store-listing-screen .logo-vertical img {
    display: block;
}

.store-listing-screen .close-listing-screen {
    width: 14px;
    height: 14px;
    background: url(../images/close-icon.png) no-repeat left top / 100%;
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1000;
}

.screen-thumb {
    width: calc(100% - 40px);
    margin: 0 0 25px 40px;
    padding: 50px 0 0 0;
    overflow: auto;
}

.screen-thumb ul {
    width: auto;
    float: left;
    display: flex;
    white-space: nowrap;
}

.screen-thumb ul li {
    float: left;
    display: block;
    padding: 0 10px 0 0;
}

.screen-thumb ul li a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    font-weight: 500;
    position: relative;
    padding: 0 0 10px;
    min-width: 160px;
}

.screen-thumb ul li a:after {
    width: 100%;
    height: 5px;
    background: rgba(0, 0, 0, 0.2);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 50px;
}

.screen-thumb ul li.active a:after {
    background: #fff;
}

.screen-thumb::-webkit-scrollbar {
    height: 5px;
}

.screen-thumb::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.screen-thumb::-webkit-scrollbar-thumb {
    background: #888;
}

.screen-thumb::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.screen-tab-content {
    width: calc(100%);
    margin: 0 0 0 15px;
    padding: 0 0 70px;
    overflow: auto;
}

.card-group-bx {
    width: auto;
    float: left;
    display: flex;
    white-space: nowrap;
}

.card-big-bx {
    width: 310px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    float: left;
    display: block;
    margin: 0 15px 0 0;
    height: 35rem;
    max-height: 600 px;
    white-space: normal;
    position: relative;
}

.card-big-bx .white-bg-bx {
    width: 100%;
    background: #fff;
    border-radius: 20px;
    height: 88%;
}

.card-big-bx .top-section {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    padding: 20px 25px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-big-bx .btm-section {
    width: 100%;
    height: calc(100% - 75px);
    padding: 25px 20px;
    position: relative;
}

.card-big-bx .btm-section .btm-content {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 25px 25px;
}

.card-big-bx .store-time {
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 600;
    display: block;
    position: relative;
    padding: 0 0 0 22px;
}

.card-big-bx .store-time.open-icon:before {
    width: 16px;
    height: 16px;
    background: url("../images/clock_icon.svg") no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.card-big-bx .store-time.close-icon:before {
    width: 16px;
    height: 16px;
    background: url("../images/clock-close.png") no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.card-big-bx .store-name {
    display: block;
    font-size: 15px;
    line-height: 22px;
    color: #1e0013;
    font-weight: 600;
    margin-bottom: 10px;
    max-height: 45px;
}

.card-big-bx address {
    /* font-size: 14px; */
    font-size: 13px;
    line-height: 18px;
    color: #1e0013;
    font-weight: 500;
}

.card-big-bx .location-away,
.card-big-bx .get-direction {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    font-weight: 500;
}

.card-big-bx .get-direction a {
    color: #1b2b81;
    text-decoration: underline;
}

.card-big-bx .view-store-details {
    display: block;
    text-align: center;
    padding: 0px 0 0;
}

.card-big-bx .view-store-details a {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 500;
    position: relative;
    padding: 0 0 0 10px;
}

.card-big-bx .view-store-details a:before {
    width: 8px;
    height: 8px;
    background: url(../images/view-store-icon.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 4px;
    left: -20px;
}

.card-big-bx .view-on-map {
    position: absolute;
    bottom: -30px;
    left: 50%;
    margin-left: -70px;
}

.survey-bg {
    background: #cfdef3;
}

.survey-intro-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 15px;
    background: #cfdef3;
}

.survey-intro-screen .survey-title {
    display: block;
    font-size: 16px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 600;
    text-align: center;
}

.call-slide-section {
    width: 100%;
    height: 45%;
    background-image: url("./../images/Rectangle.svg");
    border-radius: 20px 20px 0 0;
    position: fixed;
    left: 0;
    bottom: -100%;
    z-index: 99;
    display: none;
}

.call-slide-section div {
    width: 100%;
}

.call-slide-section .call-section-content {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.call-slide-section .call-screens {
    display: none;
}

.call-slide-section .txt1 {
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    display: block;
    padding: 30px 20px 30px;
    text-align: center;
}

.call-slide-section .txt1 strong {
    font-weight: 600;
}

.call-slide-section .txt2 {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    font-weight: 500;
    display: block;
    padding: 0;
    text-align: center;
}

.call-slide-section .txt2 a {
    color: #fff;
    text-decoration: underline;
}

.call-slide-section .button-white {
    max-width: 274px;
    margin: 0 auto 30px;
}

.call-slide-section .txt3 {
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    display: block;
    padding: 0 0 10px;
    text-align: center;
}

.call-slide-section .mobile-num-input {
    width: 100%;
    max-width: 274px;
    margin: 0 auto 20px;
    height: 58px;
    background: #fff;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 16px;
    font-size: 18px;
    line-height: 22px;
    color: #c8c8c8;
    display: block;
}

.call-slide-section .button-blue {
    width: 100%;
    max-width: 274px;
    margin: 0 auto 30px;
}

.call-slide-section .thank-you-icon {
    width: 40px;
    height: 40px;
    background: url(../images/thank-you-icon.png) no-repeat left top / 100%;
    margin: 0 auto 30px;
    display: block;
}

.call-slide-section .txt4 {
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    display: block;
    padding: 0 0 20px;
    text-align: center;
}

.call-slide-section .close-call-section {
    width: 14px;
    height: 14px;
    background: url(../images/close-icon-white.png) no-repeat left top / 100%;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
}

.map-section {
    width: 100%;
    height: calc(100% - 100px);
    background: #000;
    position: fixed;
    right: 0;
    bottom: -100%;
    z-index: 9;
    display: none;
}

.map-section iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.map-section .view-list-but {
    position: absolute;
    bottom: 50px;
    left: 50%;
    margin-left: -70px;
    z-index: 9;
}

.view-listing-screen {
    width: 100%;
    position: fixed;
    right: 0;
    bottom: -100%;
    z-index: 9;
    display: none;
}

.view-listing-screen .card-big-bx {
    width: 100%;
    margin: 0;
    background: #fff;
    border-radius: 20px 20px 0 0;
}

.view-listing-screen .card-big-bx .white-bg-bx {
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.25);
}

.store-details-section {
    width: 100%;
    padding: 25px 0 0;
}

.store-details-section .container {
    border-bottom: 1px solid #e0e0e0;
    padding: 0 25px 25px;
}

.store-details-section .store-name {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    padding: 0 0 0 35px;
    position: relative;
    max-width: 70%;
    float: left;
}

.store-details-section .store-name strong {
    display: block;
    font-size: 22px;
    line-height: 30px;
    /* font-weight: 600; */
}

.store-details-section .back-arrow {
    width: 12px;
    height: 20px;
    background: url(../images/arrow-lft-blue.webp) no-repeat left top / 100%;
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
}

.store-details-section .store-open-close {
    padding: 0 0 0px 35px;
    position: relative;
    margin: 15px 0 0 0;
}

.store-details-section .store-open-close .store-status {
    position: absolute;
    top: 0;
    left: 0;
}

.store-details-section .store-open-close .open-icon {
    width: 18px;
    height: 18px;
    background: url(../images/clock_icon.svg) no-repeat left top / 100%;
    display: block;
}

.store-details-section .store-open-close .timing-txt {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    font-weight: 600;
}

.store-details-section .business-hour {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #9a9a9a;
}

.store-details-section .store-add-photo {
    padding: 0 0 15px 35px;
}

.store-details-section .store-add-photo address {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    float: left;
    max-width: 50%;
}

.store-details-section .store-add-photo .landmark-txt {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    float: left;
    max-width: 50%;
    left: -15%;
    top: 4%;
}

.store-details-section .store-add-photo .store-photo {
    display: block;
    float: right;
    width: 48%;
    text-align: center;
}

.store-details-section .store-add-photo .store-photo a {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: #9a9a9a;
    text-decoration: underline;
}

.store-details-section .button-group {
    padding: 10px 0;
    max-width: 400px;
    margin: 0 auto;
}

.store-details-section .button-group .buy-online-but {
    background: #ebebeb;
    border-radius: 16px;
    padding: 17px 15px;
}

.store-details-section .get-direction {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #1e0013;
    font-weight: 700;
    padding: 0 0 5px;
    font-family: "Montserrat";
    font-style: normal;
}

.store-details-section .get-direction a {
    color: #1b2b81;
    text-decoration: underline;
}

.store-details-section .get-direction.get-direction-icon {
    position: relative;
    padding-left: 35px;
}

.store-details-section .get-direction.get-direction-icon-mob {
    position: relative;
}

.store-details-section .get-direction.get-direction-icon:before {
    width: 15px;
    height: 16px;
    background: url(../images/direction_arrow-new.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 5px;
    left: 2px;
}

.store-details-section .distance-txt,
.store-details-section .parking-txt {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    margin: 0 5px;
    position: relative;
    padding: 0 0 0 30px;
}

.store-details-section .distance-txt,
.store-details-section .parking-txt-mob {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    margin: 0 5px;
    position: relative;
}

.store-details-section .distance-txt:before {
    width: 25px;
    height: 11px;
    background: url(../images/distance-icon.png) no-repeat left top / 100%;
    content: "";
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
}

.store-details-section .parking-txt:before {
    width: 21px;
    height: 21px;
    background: url(../images/parking_icon.svg) no-repeat left top / 100%;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 2px;
}

.store-details-section .parking-txt-mob:before {
    width: 21px;
    height: 21px;
    background: url(../images/parking-icon.png) no-repeat left top / 100%;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -25px;
}

.product-available-bx {
    width: 100%;
    padding: 10px 0 25px;
}

.product-available-bx h6 {
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 600;
    text-align: center;
    padding: 0 0 25px;
}

.prod-ava-thumb {
    width: 100%;
    padding: 0 0 25px;
}

.prod-ava-thumb ul {
    width: 100%;
    float: left;
    display: block;
    text-align: center;
}

.prod-ava-thumb ul li {
    display: inline-block;
    vertical-align: top;
    padding: 0 12px;
}

.prod-ava-thumb ul li a {
    display: inline-block;
    text-decoration: none;
    color: #1b2b81;
}

.prod-ava-thumb .product-txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #1e0013;
}

.prod-ava-thumb .product-icon {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: center;
    margin: 0 0 10px;
}

.prod-ava-thumb .product-icon svg,
.prod-ava-thumb .product-icon svg * {
    stroke: #26cecd;
}

.prod-ava-thumb .active .product-icon img,
.prod-ava-thumb .active .product-icon img * {
    /*stroke: #D6D6D6;*/
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(1%);
}

.prod-ava-thumb .active .product-txt {
    color: #9a9a9a;
}

/*.prod-ava-thumb .active .product-icon img{filter: invert(100%) sepia(0%) saturate(4102%) hue-rotate(127deg) brightness(88%) contrast(90%);}*/

.view-store-offer-bx {
    padding: 0 15px 30px;
}

.view-store-offer-bx .inner-cont {
    border: 3px dashed #294e95;
}

.view-store-offer-bx .title-section {
    display: block;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
}

.view-store-offer-bx .title-section h4 {
    color: #294e95;
    font-family: "Poppins";
    font-style: normal;
    line-height: 34px;
    font-weight: 700;
}

.view-store-offer-bx .title-section h4.icon {
    padding: 0 0 0 50px;
    position: relative;
}

.view-store-offer-bx .title-section h4.icon:before {
    width: 34px;
    height: 34px;
    background: url(../images/union_icon.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.view-store-offer-bx .title-section .plus-icon {
    font-family: "Poppins";
    font-style: normal;
    font-size: 26px;
    line-height: 28px;
    color: #294e95;
    font-weight: 700;
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.view-store-offer-bx .title-section.open .plus-icon {
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.view-store-offer-bx .cont-section {
    width: 100%;
    padding: 10px 10px 15px;
    display: none;
}

.card-offer {
    width: 100%;
    max-width: 350px;
    height: auto;
    background-image: linear-gradient(to right, #24c6dc, #514a9d);
    margin: 0 auto 20px;
    -moz-box-shadow: 5px 5px 0px #d6d6d6;
    -webkit-box-shadow: 5px 5px 0px #d6d6d6;
    box-shadow: 5px 5px 0px #d6d6d6;
    display: flex;
    justify-content: space-around;
    padding: 15px 25px;
    flex-direction: column;
}

.card-offer:last-child {
    margin-bottom: 0;
}

.card-offer .card-txt {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    display: block;
}

.card-offer .card-txt strong {
    display: block;
    font-weight: 600;
}

.card-offer .card-txt a {
    color: #fff;
    text-decoration: underline;
}

.product-details-bx {
    width: 100%;
    padding: 0 25px;
}

.product-details-bx .prod-det-txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #1e0013;
    padding: 0 0 20px 35px;
    position: relative;
}

.product-details-bx .prod-det-txt a {
    color: #1b2b81;
    text-decoration: none;
}

.product-details-bx .prod-det-txt a:hover {
    text-decoration: underline;
}

.product-details-bx .price-icon:before {
    width: 16px;
    height: 20px;
    background: url(../images/currency_icon.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.product-details-bx .emi-icon:before {
    width: 22px;
    height: 17px;
    background: url(../images/emi_icon.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.product-details-bx .payment-icon:before {
    width: 19px;
    height: 19px;
    background: url(../images/payment_icon.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.product-details-bx .mail-icon:before {
    width: 19px;
    height: 16px;
    background: url(../images/mail_icon.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.review-bx {
    width: 100%;
    padding: 30px 0;
}

.review-bx .stars {
    text-align: center;
    padding: 0 0 25px;
}

.review-bx .stars .star {
    width: 15px;
    height: 15px;
    background: url(../images/star-blue-trans.png) no-repeat left top / 100%;
    display: inline-block;
    vertical-align: top;
}

.review-bx .stars .star.active {
    background: url(../images/star-blue.png) no-repeat left top / 100%;
}

.review-bx .stars .rating {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 600;
    letter-spacing: 1px;
}

.review-bx .section-title {
    margin: 0 0 10px;
}

.review-gallery {
    width: 100%;
}

.review-gallery .item {
    padding: 0 20px 7px 5px;
}

.review-gallery .card-bx {
    min-height: 174px;
    height: 174px;
    flex-direction: column;
}

.review-gallery .card-bx h4 {
    font-size: 14px;
    line-height: 18px;
    color: #7a7a7a;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 0 0 7px;
}

.review-gallery .card-bx .posted-txt {
    display: block;
    font-size: 10px;
    line-height: 14px;
    color: #7a7a7a;
    letter-spacing: 0.75px;
    padding: 0 0 7px;
}

.review-gallery .card-bx p {
    display: block;
    font-size: 10px;
    line-height: 14px;
    color: #7a7a7a;
    letter-spacing: 0.75px;
}

.review-gallery .card-bx .stars-gray {
    text-align: left;
    padding: 0 0 15px;
}

.review-gallery .card-bx .stars-gray .star {
    background: url(../images/star-gray-trans.png) no-repeat left top / 100%;
}

.review-gallery .card-bx .stars-gray .star.active {
    background: url(../images/star-gray.png) no-repeat left top / 100%;
}
.review-gallery .card-bx .star.gold {
    background: url(../images/star.png) no-repeat left top / 100%;
}

.get-call-bx {
    width: 100%;
    padding: 0 0 40px;
}

.get-call-bx .get-call-cont {
    width: 100%;
    border: 3px dashed #aba8b1;
    padding: 20px;
    position: relative;
    padding-top: 35px;
}

.main-form {
    width: 100%;
}

.main-form ul {
    width: 100%;
    float: left;
    display: block;
    gap: 10px;
}

.main-form ul li {
    flex-grow: 1;
    float: left;
    display: block;
    padding: 0 0 20px;
}

.main-form ul li:last-child {
    padding-bottom: 0;
}

.main-form .white-container {
    width: 100%;
    height: 74px;
    background: #fff;
    border-radius: 16px;
    padding: 15px 30px 10px 60px;
    position: relative;
}

.main-form .white-container label {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #6e7191;
    letter-spacing: 0.75px;
    padding: 0 0 5px;
    font-family: "Montserrat";
    font-style: normal;
}

.main-form .white-container .textbox {
    width: 100%;
    height: 24px;
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    color: #14142b;
    letter-spacing: 0.75px;
    padding: 0;
    text-align: left;
    border-bottom: 1px solid #ececec;
}

.main-form .name-icon:before {
    width: 26px;
    height: 24px;
    background: url(../images/profile.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -12px;
}

.main-form .email-icon:before {
    width: 24px;
    height: 24px;
    background: url(../images/email_icon.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -12px;
}

.main-form .mobile-icon:before {
    width: 16px;
    height: 38px;
    background: url(../images/smartphone.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -12px;
}

.main-form .pincode-icon:before {
    width: 24px;
    height: 24px;
    background: url(../images/pincode_icon.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -12px;
}

.main-form .submit-but {
    width: 100%;
    /* height: 100%; */
    /* height: 50px; */
    height: 71px;
    margin: 0;
    border: none;
    letter-spacing: 0.75px;
    font-weight: 500;
    background: #11184f;
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
}

.about-section {
    width: 100%;
    padding: 40px 0px;
    padding-bottom: 0px;
}

.about-section .container,
.faqs-section .container {
    padding: 0 50px;
}

.about-section h4 {
    font-size: 18px;
    line-height: 22px;
    color: #7a7a7a;
    padding: 0 0 15px;
    text-align: center;
}

.about-section p {
    font-size: 12px;
    line-height: 18px;
    color: #7a7a7a;
    letter-spacing: 0.75px;
}

.most-search-bx {
    width: 100%;
    background: #aba8b1;
    -moz-box-shadow: inset 0 0 30px #c5c5c5;
    -webkit-box-shadow: inset 0 0 30px #c5c5c5;
    box-shadow: inset 0 0 30px #c5c5c5;
    padding: 20px 0;
    text-align: center;
}

.most-search-bx h4 {
    font-size: 18px;
    line-height: 22px;
    color: #1e0013;
    padding: 0 0 10px;
    font-family: "Montserrat";
    font-style: normal;
}

.most-search-bx p {
    font-size: 18px;
    line-height: 22px;
    color: #7a7a7a;
    letter-spacing: 0.75px;
}

.most-search-bx p a {
    color: #7a7a7a;
}

.most-search-bx ul {
    font-family: "Montserrat";
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    letter-spacing: 0.75px;
}

.offline-map-bx {
    position: relative;
    height: 100%;
}

.offline-map-bx .offline-map-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.offline-map-bx .offline-map-cont {
    width: 100%;
    height: 100%;
    padding: 83px 40px 0 40px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.offline-map-bx .loc-marker {
    max-width: 160px;
    display: block;
    /* margin: 0 0 10px; */
    margin-bottom: -10px;
}

.offline-map-bx .loc-marker .img-fluid {
    margin-bottom: -15px;
}

.offline-map-bx .popular-cities-bx {
    width: 100%;
    text-align: center;
    clear: both;
    display: block;
    margin: 0 0 60px;
}

.offline-map-bx .popular-cities-bx p,
.offline-map-bx .popular-cities-bx p a {
    font-size: 16px;
    line-height: 30px;
    font-family: "Montserrat";
    font-style: normal;
    color: #1e0013;
}

.offline-map-bx .popular-cities-bx p a {
    display: inline-block;
    margin: 0 5px;
    text-decoration: underline;
    font-weight: 500;
}

.offline-map-bx .facts-bx {
    max-width: 416px;
    margin: 0 auto;
    border: 1px solid #7a126b;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
}

.offline-map-bx .facts-bx p {
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    color: #1e0013;
    font-family: "Montserrat";
    font-style: normal;
}

.offline-map-bx .facts-bx p span {
    display: block;
    padding: 0 0 10px;
    text-decoration: underline;
    font-weight: 700;
    font-size: 24px;
}

.offline-map-bx .tab-section .tab-thumb {
    top: 10%;
}

.location-white-bx {
    background: #fff;
    border-radius: 16px;
    padding: 7px;
    display: flex;
    margin: 0 0 30px;
    position: relative;
}

.location-white-bx .custom-dropdown {
    border-right: 1px solid #cfdef3;
    min-width: 130px;
    padding: 0 15px;
    margin: 0 15px 0 0;
}

.location-white-bx .custom-dropdown select {
    width: 100%;
    height: 60px;
    background: none;
    border: none;
    /*font-size:20px; */
    line-height: 24px;
    color: #1b2b81;
    font-weight: 500;
    outline: none;
}

.location-white-bx .search-location {
    width: 100%;
    padding: 0 20px 0 0;
}

.location-white-bx .search-location img {
    width: 30px;
    height: calc(30px * 70 / 62);
    margin-right: 12px;
}

.location-white-bx .search-location .textbox {
    width: 100%;
    height: 60px;
    background: none;
    border: none;
    outline: none;
    /*font-size:18px;*/
    line-height: 22px;
    color: #1b2b81;
}

.location-white-bx .turnon-location-bx {
    margin-left: auto;
}

.location-white-bx .turnon-location-bx .location-but {
    width: auto;
    height: 60px;
    background: linear-gradient(90deg, #24c6dc 0%, #514a9d 100%);
    border-radius: 16px;
    border: none;
    outline: none;
    /*font-size:20px; */
    line-height: 24px;
    font-weight: 500;
    color: #fff;
    white-space: normal;
    padding: 2px 40px 0 14px;
    text-align: center;
    max-width: 200px;
    position: relative;
}

.location-white-bx .turnon-location-bx .location-but:after {
    width: 17px;
    height: 17px;
    background: url(../images/arrow-white.webp) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    right: 14px;
    margin-top: -8px;
}

.location-white-bx .turnon-location-bx .location-but:hover {
    background: #1b2b81;
    color: #fff;
    text-decoration: none;
}

.location-white-bx .turnon-location-bx .location-but:disabled {
    background: #e0e0e0;
    color: #fff;
    border-radius: 16px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
}

.location-white-bx .turnon-location-bx .location-but:enabled {
    background: #7A1248;
    border-radius: 16px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
}

.location-white-bx .locate-me-txt {
    font-family: "Montserrat";
    font-style: normal;
    border-right: 1px solid #cfdef3;
    display: flex;
    align-items: center;
    padding: 0 20px;
    /* text-decoration: underline; */
    font-size: 14px;
    line-height: 32px;
    color: #1e0013;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-right: 15px;
    min-width: 115px;
}

.location-on-desk-bx {
    height: 310px;
    width: 100%;
    position: relative;
}

.location-on-desk-bx .loc-on-map-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.location-on-desk-bx .loc-on-map-cont {
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    padding: 0 40px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.location-on-desk-bx .loc-marker {
    max-width: 160px;
    display: block;
}

.location-on-desk-bx .location-white-bx .turnon-location-bx .location-but {
    min-width: 110px;
}

.location-on-desk-bx .location-white-bx .turnon-location-bx .location-but:hover {
    background-image: linear-gradient(to right, #11184f, #11184f);
    color: #fff;
    text-decoration: none;
}

.store-listing-screen-desk {
    height: calc(100% - 310px);
    padding: 0 30px 30px;
}

.screen-thumb.black-color {
    margin: 15px 0 15px 0;
    padding: 0 0;
    width: 100%;
    overflow: auto;
}

.screen-thumb.black-color ul li {
    padding: 0 15px 0 0;
}

.screen-thumb.black-color ul li a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    min-width: 10px;
    padding: 0 15px 10px 0;
}

.screen-thumb.black-color ul li.active a:after {
    background: #000;
}

.store-listing-screen-desk .tab-section {
    height: 100%;
}

.store-listing-screen-desk .screen-tab-content {
    width: 100%;
    margin: 0;
    padding: 0 5px 0 0;
    max-height: 550px;
    overflow: auto;
}

.store-listing-screen-desk .screen-tab-content::-webkit-scrollbar {
    width: 5px;
}

.store-listing-screen-desk .screen-tab-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.store-listing-screen-desk .screen-tab-content::-webkit-scrollbar-thumb {
    background: #888;
}

.store-listing-screen-desk .screen-tab-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.store-listing-screen-desk .found-result-bx {
    display: flex;
    align-items: center;
    margin: 0 0 40px;
}

.store-listing-screen-desk .found-result-bx .result-txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1b2b81;
    /* max-width: 400px; */
    display: inline-block;
}

.store-listing-screen-desk .found-result-bx .button-blue {
    background: #11184f;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    width: auto;
    height: 40px;
    font-size: 14px;
    line-height: 32px;
    margin-left: auto;
    min-width: 140px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    color: #ffffff;
}

.store-listing-screen-desk .found-result-bx .button-blue:hover {
    text-decoration: none;
    color: #fff;
}

.store-listing-screen-desk .found-result-bx .button-blue::after {
    display: none;
}

.result-listing-bx {
    padding: 0 10px 10px 5px;
}

.result-listing-bx .result-item {
    padding: 0 0 30px;
}

.result-listing-bx .card-bx {
    background: #ffffff;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 20px;
}

.result-listing-bx .card-bx h4,
.result-listing-bx-info .card-bx-info h4 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: #1e0013;
    display: inline-block;
    vertical-align: top;
    padding: 0 0 20px;
    font-weight: 700;
}

.result-listing-bx .card-bx h4 span,
.result-listing-bx-info .card-bx-info h4 span {
    display: block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
}

.result-listing-bx .card-bx p,
.result-listing-bx-info .card-bx-info p {
    font-family: "Montserrat";
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #1e0013;
    padding: 0 0 10px;
    font-weight: 500;
}

.result-listing-bx .card-bx .call-txt,
.result-listing-bx-info .card-bx-info .call-txt {
    font-size: 12px;
    line-height: 20px;
    color: #1b2b81;
    padding: 0 0 10px;
    font-weight: 500;
    text-decoration: none;
}

.result-listing-bx .card-bx .store-time,
.result-listing-bx-info .card-bx-info .store-time {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: #1b2b81;
    font-weight: 600;
    display: block;
    position: relative;
    padding: 0 0 0 22px;
}

.result-listing-bx .card-bx .store-time.open-icon:before,
.result-listing-bx-info .card-bx-info .store-time.open-icon:before {
    width: 16px;
    height: 25px;
    background: url(../images/clock_icon.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.result-listing-bx .card-bx .distance-txt,
.result-listing-bx-info .card-bx-info .distance-txt {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: #1b2b81;
    font-weight: 600;
    display: block;
    position: relative;
    padding: 0 0 0 22px;
}

.result-listing-bx .card-bx .distance-txt::before,
.result-listing-bx-info .card-bx-info .distance-txt::before {
    width: 16px;
    height: 25px;
    background: url(../images/location-marker2-new.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.result-listing-bx .card-bx .button-gradient,
.result-listing-bx-info .card-bx-info .button-gradient,
.result-listing-bx .card-bx .button-white,
.result-listing-bx-info .card-bx-info .button-white {
    height: 44px;
    max-width: 150px;
    border-radius: 13px;
    float: right;
    padding: 0 8px;
    /*font-size:18px;*/
    letter-spacing: 0;
    margin: 0 0 12px;
}

.result-listing-bx .card-bx .button-white:hover,
.result-listing-bx-info .card-bx-info .button-white:hover {
    background: #1b2b81;
    color: #fff;
    text-decoration: none;
}

.result-listing-bx .card-bx .view-store,
.result-listing-bx-info .card-bx-info .view-store {
    font-family: "Montserrat";
    font-style: normal;
    display: inline-block;
    /* text-decoration: underline; */
    font-size: 14px;
    color: #1b2b81;
    font-weight: 400;
    position: relative;
    padding: 3px 0 0 23px;
}

.result-listing-bx .card-bx .view-store:before,
.result-listing-bx-info .card-bx-info .view-store:before {
    width: 18px;
    height: 18px;
    background: url(../images/eye_icon-new.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
}

.result-listing-bx .card-bx .share-icon,
.result-listing-bx-info .card-bx-info .share-icon {
    width: 15px;
    height: 22px;
    background: url(../images/share_icon.svg) no-repeat left 4px / 100%;
    display: inline-block;
    text-decoration: none;
    margin: 0 0 0 10px;
    right: 5px;
    top: 0;
}

.load-icon {
    width: 40px;
    height: 40px;
    background: url(../images/baseline_loop_black_24dp.webp) no-repeat left top / 100%;
    display: inline-block;
    text-decoration: none;
}

.load-icon-mob {
    width: 40px;
    height: 40px;
    background: url(../images/baseline_loop_black_24dp.webp) no-repeat left top / 100%;
    display: inline-block;
    text-decoration: none;
    margin-top: 200px;
}

.map-container {
    width: 100%;
    position: absolute;
    background: #000;
    bottom: -100%;
    left: 0;
    z-index: 2;
    padding: 130px 0 0 0;
    display: none;
}

.map-container iframe,
.map-container .map-iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.map-container .search-location-bx {
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
    padding: 0 60px 0 40px;
    z-index: 2;
}

.map-container .search-location-bx .location-white-bx {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.map-container .search-location-bx .turnon-location-bx .location-but {
    background: #e0e0e0;
    min-width: 110px;
}

.map-container .search-location-bx .turnon-location-bx .location-but:hover {
    background-image: linear-gradient(to right, #24c6dc, #514a9d);
    color: #fff;
    text-decoration: none;
}

.map-container .screen-thumb {
    margin: 0 60px 0 40px;
    position: relative;
    z-index: 2;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: auto;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    overflow: hidden;
}

.map-container .screen-thumb .result-txt {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #000;
    display: block;
    padding: 15px 0 0 0;
}

.map-container .view-button {
    position: absolute;
    bottom: 60px;
    left: 50%;
    margin-left: -70px;
    z-index: 2;
}

.store-content .container {
    max-width: 1340px;
}

.dir-rtl {
    direction: rtl;
}

.dir-ltr {
    direction: ltr;
}

.store-banner-desk {
    width: 100%;
    height: 360px;
    padding: 30px 0;
    background: url(/assets/images/Mask_group.svg) no-repeat left top / 100% 100%;
    align-items: center;
    background-size: cover;
}

.store-banner-desk .go-back {
    width: 20px;
    height: 20px;
    background: url(../images/back_arrow_white.svg) no-repeat left top / 100%;
    display: inline-block;
    text-decoration: none;
    position: absolute;
    top: -60px;
}

.store-banner-desk .store-title {
    font-family: "Montserrat";
    font-style: normal;
    font-size: 52px;
    line-height: 52px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 5px;
}

.store-banner-desk .store-title .share-icon {
    width: 26px;
    height: 30px;
    margin-left: 10px;
    background: url(../images/share_icon_white.svg) no-repeat left top / 100%;
    display: inline-block;
}

.store-banner-desk .store-title .edit-icon {
    width: 32px;
    height: 32px;
    background: url(../images/edit_icon.svg) no-repeat left top / 100%;
    display: inline-block;
}

.store-banner-desk .store-sub-title {
    display: block;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    padding: 0 0 30px;
}

.rate-bx {
    background: rgb(255, 255, 255);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    max-width: 260px;
}

.rate-bx .google-icon {
    width: 25px;
    display: inline-block;
    margin: 0 10px 0 0;
}

.rate-bx .google-icon img {
    display: block;
}

.rate-bx .rate-txt {
    font-size: 1.3rem;
    line-height: 28px;
    color: #7A126B;
    font-weight: 800;
    font-family: 'Montserrat-SemiBold';
}

.rate {
    display: inline-block;
    padding: 0 10px 0 0;
}

.rate .star {
    width: 24px;
    height: 24px;
    background: url(../images/star2.png) no-repeat left top / 100%;
    display: inline-block;
}

.rate .star.checked {
    background: url(../images/star.png) no-repeat left top / 100%;
}

.star.half:after {
    margin-left: -24px !important;
}

.store-banner-desk .button-group {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    width: 100%;
    max-width: 270px;
    float: right;
    margin-right: 15px;
}

.store-banner-desk .button-group .button-white-rd {
    background: #ebebeb;
    max-width: 100%;
    border-radius: 16px;
    padding: 0 20px;
    justify-content: flex-start;
    font-weight: 400;
    line-height: 22px;
    color: #1b2b81;
    height: 58px;
    font-size: 18px;
    margin: 0 0 8px;
    position: relative;
    font-family: "Montserrat";
    font-style: normal;
}

.store-banner-desk .button-group .button-blue-rd {
    max-width: 100%;
    border-radius: 16px;
    padding: 0 20px;
    justify-content: flex-start;
    font-weight: 600;
    height: 58px;
    font-size: 18px;
    margin: 0 0 8px;
    position: relative;
}

.store-banner-desk .button-group .online-but:after {
    width: 24px;
    height: 24px;
    background: url(../images/cart_icon_purple.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -12px;
}

.store-banner-desk .button-group .tel-but:after {
    width: 23px;
    height: 23px;
    background: url(../images/call-icon.webp) no-repeat left top / 100%;
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -12px;
}

.store-banner-desk .button-group .get-dir-but:after {
    width: 18px;
    height: 17px;
    background: url(../images/arrow-white.webp) no-repeat left top / 100%;
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8px;
}

.pad-top-btm {
    padding-top: 40px;
    padding-bottom: 20px;
}

.border-right {
    border-right: 1px solid #e0e0e0;
}

.store-det-desk {
    padding-top: 20px;
}

.store-det-desk .plus-code-txt {
    font-size: 16px;
    line-height: 22px;
    color: #1b2b81;
    font-weight: 500;
    display: block;
    padding: 0 0 5px;
}

.store-det-desk .plus-code-txt strong {
    font-weight: 700;
}

.store-det-desk .store-open-close {
    margin-bottom: 15px;
}

.store-det-desk .store-open-close .timing-txt,
.store-det-desk .business-hour,
.store-det-desk .distance-txt,
.store-det-desk .parking-txt {
    font-size: 14px;
    line-height: 26px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    color: #1e0013;
}

.store-det-desk .business-hour {
    font-weight: normal;
}

.store-det-desk .distance-txt {
    display: block;
    padding-left: 35px;
    margin: 0 0 15px;
}

.store-det-desk .parking-txt {
    display: block;
    padding-left: 35px;
    margin: 0;
}

.store-details-section .address-txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #1e0013;
    display: block;
    margin: 0 0 25px;
    position: relative;
    padding: 0 0 0 35px;
    max-width: 380px;
}

.store-details-section .landmark-txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #1e0013;
    display: block;
    margin: 0 0 25px;
    position: relative;
    padding: 0 0 0 35px;
    max-width: 380px;
}

.store-details-section .address-txt:before {
    width: 20px;
    height: 24px;
    background: url(../images/location-marker2-new.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
}

.store-details-section .landmark-txt:before {
    width: 20px;
    height: 24px;
    background: url(../images/icon-segments-grade.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
}

.store-details-section .review-txt {
    font-size: 14px;
    line-height: 26px;
    color: #1b2b81;
    display: block;
    margin-top: -18px !important;
    margin: 0 0 25px;
    position: relative;
    padding: 0 0 0 35px;
    max-width: 380px;
}

.store-details-section .review-txt:before {
    width: 20px;
    height: 24px;
    background: url(../images/star-blue.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 2px;
}

.store-details-section .plus-code-txt {
    font-size: 16px;
    line-height: 22px;
    color: #1b2b81;
    font-weight: 500;
    display: block;
    padding: 0 0 5px;
    margin-top: 12px;
}

.store-details-section .plus-code-txt strong {
    font-weight: 700;
    font-family: "Montserrat";
    font-style: normal;
    color: #1e0013;
}

.product-avail-desk h6 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 0;
    color: #1b2b81;
}

.product-avail-desk .prod-ava-thumb .product-txt {
    font-size: 16px;
    line-height: 20px;
}

.product-avail-desk .product-details-bx .prod-det-txt {
    font-size: 16px;
    line-height: 20px;
}

.store-photo-desk {
    padding: 0 20px 40px;
}

.see-photos-txt {
    font-family: "Montserrat";
    font-style: normal;
    display: block;
    text-align: center;
    width: 100%;
    padding: 30px 0 0 0;
    color: #9a9a9a;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    text-decoration: underline;
}

.store-photo-desk .see-photos-txt a {
    font-size: 14px;
    line-height: 20px;
    color: #9a9a9a;
    font-weight: 500;
    text-decoration: underline;
}

.store-photo img,
.store-photo-desk img {
    border-radius: 15px;
}

.store-photo-desk img {
    object-fit: cover;
    height: 130px;
    /* height: 180px; */
}

.form-gallery-row {
    padding: 50px 0 20px;
}

.form-gallery-row .review-bx {
    padding-top: 0;
    padding-bottom: 10px;
}

#featuredProducts .card-bx .product-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

#featuredProducts .card-bx .product-desp {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
}

#featuredProducts .card-bx .product-feature-list {
    padding-left: 15px;
    list-style: disc;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #1e0013;
}

#featuredProducts .card-bx .product-buy-now-btn {
    background: #11184f;
    border-radius: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    width: 80%;
}

#featuredProducts .button-gradient {
    width: 102px;
    height: 32px;
    font-size: 12px;
}

.survey-screen-bx {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 9999;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.survey-screen-bx .container {
    padding: 0 30px;
    max-width: 800px;
}

.survey-screen-bx .pad-top {
    padding: 25px 0 0 0;
}

.survey-screen-bx .survey-screen {
    width: 100%;
    position: relative;
    visibility: hidden;
    position: absolute;
    bottom: -100%;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.survey-screen-bx .survey-screen.survey-main-screen {
    right: 0%;
    bottom: 0;
}

.survey-screen-bx .button-white {
    width: 270px;
    position: relative;
    /* bottom: 60px; */
    left: 50%;
    margin-left: -135px;
    margin-top: 110px;
    padding-left: 90px;
}

.submitButton {
    margin-top: 150px;
}

.survey-screen-bx .button-white:disabled {
    background-color: #555;
}

.survey-screen-bx .button-white:after {
    transform: rotate(90deg);
}

.survey-screen-bx .thank-you-screen .thank-you-cont {
    max-width: 260px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-flow: column;
    justify-content: center;
}

.survey-screen-bx .thank-you-screen .thank-you-cont .thank-you-img {
    display: block;
    padding: 0 0 40px;
}

.survey-screen-bx .logo-vertical {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.survey-screen-bx .logo-vertical img {
    display: block;
}

/* .survey-screen-bx .close-listing-screen { width:14px; height:16px; background:url(../images/close-icon2.png) no-repeat left top / 100%; display:block; position:absolute; top:15px; right:20px; z-index: 9;} */
.survey-screen-bx .close-listing-screen {
    width: 21px;
    height: 25px;
    background: url(../images/close-icon2.png) no-repeat left top / 100%;
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
}

/* .survey-screen-bx .close-listing-screen:after{
  position: absolute;
    top: 0;
    left: 0;
    content: '\2716';
    font-size: 22px;
    color: #1B2B81;
    color: #76808a; 
} */
.survey-content {
    width: 100%;
}

.survey-content h4 {
    font-size: 16px;
    line-height: 18px;
    color: #1b2b81;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    padding: 0 0 10px;
    margin: 0 0 20px;
}

.survey-content h4.border-btm {
    border-bottom: 1px solid #1b2b81;
}

.survey-content .survey-icon {
    width: 36px;
    margin: 0 auto 30px;
    display: block;
}

.survey-content .survey-icon img {
    display: block;
}

.survey-content .screen-number {
    width: 100%;
    text-align: center;
    margin: 0 0 20px;
}

.survey-content .screen-number li {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px;
}

.survey-content .screen-number li.active {
    background: #1b2b81;
}

.survey-content p {
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    padding: 0 0 20px;
}

.survey-content p span {
    font-size: 12px;
    display: block;
}

.survey-content .radio-bx {
    width: 100%;
}

.survey-content .radio-bx ul {
    width: auto;
    text-align: center;
    position: relative;
}

.survey-content .radio-bx ul:after {
    width: 80%;
    height: 5px;
    background: #1b2b81;
    content: "";
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -40%;
}

.survey-content .radio-bx ul li {
    display: inline-block;
    vertical-align: top;
    margin: 0 8px;
}

.survey-content .radio-bx .rad-num {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 0 0 5px;
}

.survey-content .radio-main {
    position: relative;
}

.survey-content .radio-main input[type="radio"] {
    position: absolute;
    visibility: hidden;
}

.survey-content .radio-main label {
    display: block;
    position: relative;
    z-index: 9;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.survey-content .radio-main .check {
    display: block;
    position: absolute;
    border: 5px solid #fff;
    background: #fff;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    top: 0;
    left: 0;
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
}

.survey-content .radio-main .check:before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

.survey-content .radio-main input[type="radio"]:checked~.check {
    border: 5px solid #fff;
}

.survey-content .radio-main input[type="radio"]:checked~.check::before {
    background: #1b2b81;
}

.survey-content .check-bx {
    width: 100%;
}

.survey-content .check-bx ul {
    width: auto;
}

.survey-content .check-bx ul li {
    width: 100%;
    float: left;
    display: block;
    padding: 0 0 10px;
}

.survey-content .check-bx .check-main {
    display: block;
}

.survey-content .check-bx .check-main input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.survey-content .check-bx .check-main label {
    position: relative;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    color: #1b2b81;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.survey-content .check-bx .check-main label:before {
    content: "";
    -webkit-appearance: none;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    padding: 0;
    display: block;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    flex: 0 0 auto;
    float: left;
}

.survey-content .check-bx .check-main input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 13px;
    width: 6px;
    height: 14px;
    border: solid #1b2b81;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.slider-wrapper {
    height: 50px;
    margin-bottom: 15px;
}

.slider-wrapper .noUi-horizontal {
    height: 5px;
}

.slider-wrapper .noUi-target {
    background: #fff;
    border: 1px solid #333;
    border-radius: 20px;
    border: none;
    box-shadow: none;
}

.slider-wrapper .noUi-connect {
    background: #1b2b81;
    border: 1px solid #1b2b81;
}

.slider-wrapper .noUi-horizontal .noUi-handle {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    right: -15px;
    cursor: pointer;
}

.slider-wrapper .noUi-pips-horizontal {
    padding: 10px 0 0 0;
    height: 40px;
    top: 100%;
    left: 0;
    width: calc(100% - 10px);
    margin: 0 5px;
}

.noUi-marker-horizontal,
.noUi-horizontal .noUi-tooltip,
.slider-wrapper .noUi-handle:after,
.slider-wrapper .noUi-handle:before {
    display: none;
}

.noUi-value-horizontal {
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.clear-both {
    clear: both;
}

/* Responsive Start */
@media (min-width: 1201px) {
    .left-column {
        width: calc(100% - 640px);
        flex: 0 0 auto;
    }

    .right-column {
        width: 640px;
    }

    .card-bx .product-title {
        font-size: 20px;
        line-height: 30px;
    }

    .card-bx .product-desp {
        font-size: 14px;
        line-height: 24px;
    }

    .button-gradient,
    .button-white-rd {
        max-width: 150px;
        height: 48px;
        font-size: 16px;
        line-height: 20px;
    }

    .view-offer-bx .button-gradient,
    .get-assist-bx .button-white-rd,
    .get-assist-bx .button-gradient {
        max-width: 150px;
    }
}

@media (min-width: 992px) {
    .d-tlr-pad {
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .store-content .get-call-bx .get-call-cont .section-title {
        /*font-size:24px;*/
        line-height: 28px;
        text-align: center;
        position: absolute;
        top: -16px;
        left: 50%;
        background: #e6e2e4;
        transform: translateX(-50%);
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 700;
    }

    .store-content .get-call-bx .get-call-cont .section-title:after {
        display: none;
    }

    .store-content .review-bx .section-title,
    .store-content .view-offer-bx .section-title,
    .store-content .get-assist-bx .section-title {
        /*font-size:24px; */
        float: left;
        margin: 0 0 20px 5px;
        padding: 0 0 5px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
    }

    .store-content .review-bx .section-title:after,
    .store-content .view-offer-bx .section-title:after,
    .store-content .get-assist-bx .section-title:after {
        width: calc(100% + 30px);
        bottom: -5px;
    }

    .store-content .review-bx .stars {
        float: right;
    }

    .store-content .review-bx .review-gallery .stars {
        float: none;
    }

    .store-content .review-bx .stars .rating {
        font-size: 18px;
        line-height: 22px;
        margin-right: 0px;
        font-family: 'Montserrat-SemiBold';
    }

    .store-content .review-bx .stars .star {
        width: 18px;
        height: 24px;
    }

    .screen-thumb::-webkit-scrollbar {
        height: 5px;
    }

    .screen-thumb::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .screen-thumb::-webkit-scrollbar-thumb {
        background: #888;
    }

    .screen-thumb::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .store-listing-screen-desk .screen-tab-content::-webkit-scrollbar {
        width: 5px;
    }

    .store-listing-screen-desk .screen-tab-content::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .store-listing-screen-desk .screen-tab-content::-webkit-scrollbar-thumb {
        background: #888;
    }

    .store-listing-screen-desk .screen-tab-content::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .left-column {
        width: 50%;
    }

    .right-column {
        width: 50%;
    }

    .offline-map-bx .offline-map-cont {
        padding: 0 15px;
    }

    .location-on-desk-bx .loc-on-map-cont {
        padding: 0 15px;
    }

    .location-white-bx .custom-dropdown {
        min-width: 110px;
        padding: 0 10px;
    }

    .location-white-bx .custom-dropdown select {
        font-size: 16px;
    }

    .location-white-bx .search-location img {
        width: 15%;
    }

    .location-white-bx .search-location .textbox {
        font-size: 16px;
    }

    .location-white-bx .turnon-location-bx .location-but {
        font-size: 16px;
        line-height: 20px;
        padding: 0 30px 0 10px;
    }

    .location-white-bx .turnon-location-bx .location-but:after {
        width: 14px;
    }

    .result-listing-bx .card-bx .button-gradient {
        font-size: 16px;
    }

    .result-listing-bx .card-bx .view-store {
        font-size: 12px;
    }

    .store-banner-desk .store-title {
        font-size: 54px;
        line-height: 60px;
    }

    .prod-ava-thumb ul li {
        padding: 0 4px;
    }

    .screen-thumb.black-color ul li {
        padding-right: 10px;
    }

    .card-bx.min-height {
        min-height: 236px;
    }

    .store-get-assist-bx .card-bx.min-height {
        min-height: 251px;
    }
}

@media (max-width: 991px) {
    .left-column {
        width: 100%;
    }

    .right-column {
        width: 100%;
    }

    header {
        /* background: #4d144a; */
        background-image: url("./../images/Rectangle.svg");
        background-size: cover;
        background-position: center;
    }

    header .container {
        position: relative;
    }

    header .logo {
        margin: 0 20px 0 0;
    }

    header .main-nav-bx {
        width: auto;
        position: absolute;
        top: 0;
        right: 0;
    }

    header .main-nav-bx .main-nav {
        -moz-transition: all 0.4s ease-in-out 0s;
        -webkit-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
        width: auto;
        display: block;
        background: #4d144a;
        position: fixed;
        top: 0;
        right: -100%;
        max-width: 260px;
        height: 100%;
        z-index: 2;
    }

    header .main-nav-bx .main-nav ul {
        padding: 60px 0 0 0;
    }

    header .main-nav-bx .main-nav ul li {
        font-size: 20px;
        line-height: 24px;
        width: 100%;
        margin: 0 0 20px;
        height: 34px;
    }

    header .main-nav-bx .main-nav ul li:last-child {
        margin-bottom: 0;
    }

    header .main-nav-bx .main-nav ul li a {
        color: #fff;
        height: auto;
        padding: 0 40px;
    }

    header .main-nav-bx .main-nav ul li .menu-icon {
        /* display: none; */
    }

    header .main-nav-bx .main-nav ul li .menu-txt {
        width: auto;
        opacity: 1;
        /* position: static; */
        max-width: 100% !important;
        background: none;
    }

    header .main-nav-bx .main-nav ul li:hover .menu-txt,
    header .main-nav-bx .main-nav ul li a.active .menu-txt,
    header .main-nav-bx .main-nav ul li.active a .menu-txt {
        /* width: 100%; */
    }

    header .main-nav-bx .main-nav ul li .header-icons {
        padding: 0 40px;
        justify-content: space-between;
    }

    header .main-nav-bx .main-nav.menu-open {
        right: 0;
    }

    header .main-nav-bx .main-nav ul {
        display: block;
    }

    header .main-nav-bx .main-nav .main-nav-close {
        /* width: 14px;
        height: 14px; */
        width: 20px;
        height: 32px;
        background: url(../images/close-icon.png) no-repeat left top / 100%;
        position: absolute;
        top: 20px;
        right: 20px;
        text-decoration: none;
        z-index: 10;
    }

    footer .copyright-txt {
        padding: 0 15px;
        max-width: 310px;
        margin: 0 auto;
    }

    footer .intel-img-bx {
        margin: 0 auto 15px;
    }

    footer .copyright-txt p {
        font-size: 8px;
        line-height: 12px;
    }

    .section-title {
        text-align: center;
        padding: 0;
        margin: 0 0 15px;
    }

    .section-title:after {
        display: none;
    }

    .view-offer-bx .item {
        padding: 0 20px 7px 20px;
    }

    .get-assist-bx .item {
        padding: 0 20px 7px 20px;
    }

    .card-bx .product-title {
        font-size: 14px;
        line-height: 16px;
    }

    .card-bx .product-desp {
        font-size: 10px;
        line-height: 12px;
    }

    .prod-ava-thumb ul li {
        padding: 0 10px;
    }

    .product-avail-desk .prod-ava-thumb .product-txt,
    .product-avail-desk .product-details-bx .prod-det-txt {
        font-size: 14px;
        line-height: 18px;
    }

    .pad-top-btm {
        padding: 0;
    }

    .prod-ava-border {
        border-right: none !important;
    }

    .store-content .view-offer-bx .item,
    .store-content .get-assist-bx .item {
        padding: 0 20px 7px 5px;
    }

    .bx-controls .bx-controls-direction a {
        display: none;
    }

    .card-bx.min-height {
        min-height: 182px;
        background-size: cover;
    }

    .store-get-assist-bx .card-bx.min-height {
        min-height: 200px;
    }

    header .main-nav-bx .main-nav ul li a {
        color: #fff;
        height: auto;
        padding: 0 10px;
        white-space: nowrap;
        text-align: center;
    }

    header .main-nav-bx .main-nav ul li .menu-icon.store-icon {
        width: 27px;
        height: 30px;
    }

    header .main-nav-bx .main-nav ul li .menu-icon.warranty-icon {
        width: 28px;
        height: 39px;
        margin-right: 5px;
    }

    header .main-nav-bx .main-nav ul li .menu-icon.globe-icon {
        width: 43px;
        height: 43px;
        margin-left: 3px;
        margin-right: 0;
    }

    header .main-nav-bx .main-nav ul li .header-icons a {
        color: #fff;
        height: auto;
        padding: 0px 13px;
    }
}

.thumbnail-gallery-bx {
    margin-top: 10px;
}

@media (max-width: 767px) {
    .card-big-bx .btm-section {
        position: relative;
        height: calc(100% - 75px);
    }

    .card-big-bx .white-bg-bx {
        height: 90%;
    }

    .gm-style .gm-style-iw-c {
        max-width: 340px !important;
    }

    .card-big-bx .view-store-details {
        text-align: left;
        margin-left: 20px;
        margin-top: 25px;
    }

    .card-big-bx .view-store-details a {
        padding: 0px;
        color: black;
    }

    /* .thumbnail-gallery-bx{
    display: none;
  } */
    .location-tab-bx .section-heading {
        font-size: 15px;
        line-height: 47px;
        font-weight: 600;
        color: #1b2b81;
        display: none;
        padding: 0 0 0 28px;
        text-transform: uppercase;
        max-width: 200px;
        position: relative;
        margin: 0 0 25px;
    }
}

@media (max-height: 700px) {
    .card-big-bx {
        height: 33rem;
    }

    .location-tab-bx .section-heading {
        font-size: 15px;
        line-height: 47px;
        font-weight: 600;
        color: #1b2b81;
        display: block;
        padding: 0 0 0 28px;
        text-transform: uppercase;
        max-width: 200px;
        position: relative;
        margin: 0 0 25px;
    }
}

/* Responsive end */

.survey-header-text {
    font-family: "Arial Black";
    color: #e96baf;
    font-size: 32px;
    line-height: 1;
    margin-bottom: 15px;
    text-align: center;
}

.button-white.nextButton.disabled,
.button-white.submitButton.disabled {
    background-color: #eeeeee;
}

.search-places {
    min-height: 100px;
    position: absolute;
    background: #f8f8f8;
    width: calc(100% - 135px);
    margin-left: -16px;
    padding: 5px;
    font-size: 0.9em;
    font-family: "Poppins";
    color: #565656;
    top: 100%;
    right: 0;
    box-shadow: 2px 2px 8px #7d7d7d;
    border-radius: 0 0 16px 16px;
}

.search-places ul li {
    display: block;
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    color: #1b2b81;
    font-weight: 500;
    margin: 10px 0;
    cursor: pointer;
}

.search-places ul li:hover {
    background-color: #5ec7e4;
}

@media (max-width: 991px) {
    .pcpal {
        background-image: url(../images/pcpal_mobile.webp);
    }

    .bookademo {
        background-image: url(../images/bookademo_mobile.webp);
    }

    .location-tab-bx .section-heading {
        font-size: 15px;
        line-height: 47px;
        font-weight: 600;
        color: #1b2b81;
        display: none;
        padding: 0 0 0 28px;
        text-transform: uppercase;
        max-width: 200px;
        position: relative;
        margin: 0 0 25px;
    }
}

/* TOASTER BLOCK*/
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */
.toast-title {
    font-weight: bold;
    margin-bottom: 8px;
}

.toast-message {
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: #ffffff;
}

.toast-message a:hover {
    color: #cccccc;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
}

/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #ffffff;
}

.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
}

.toast-success {
    background-color: #51a351;
}

.toast-error {
    background-color: #bd362f;
}

.toast-info {
    background-color: #2f96b4;
}

.toast-warning {
    background-color: #f89406;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}

.result-listing-bx .card-bx .directions,
.result-listing-bx-info .card-bx-info .directions {
    font-family: "Montserrat";
    font-style: normal;
    display: inline-block;
    text-decoration: underline;
    font-size: 12px;
    line-height: 17px;
    color: #1b2b81;
    font-weight: 400;
    position: relative;
    padding: 0 0 0 16px;
}

.result-listing-bx .card-bx .directions::before,
.result-listing-bx-info .card-bx-info .directions::before {
    width: 8px;
    height: 8px;
    background: url(../images/direction_arrow-new.svg) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 4px;
    right: -16px;
}

.directions {
    display: inline-block;
    text-decoration: underline;
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 800;
    position: relative;
    padding: 0 0 0 16px;
}

.temp_msg {
    color: #4ac0e0 !important;
    font-size: 13px !important;
    padding: 0px 22px !important;
    font-weight: 500;
}

.temp_msg::before {
    width: 16px;
    height: 25px;
    background: url(../images/clock-close.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.temp_msg_mob {
    color: #1b2b81 !important;
    font-size: 13px !important;
    padding: 0px 22px !important;
    font-weight: 500;
}

.temp_msg_mob::before {
    width: 16px;
    height: 25px;
    background: url(../images/clock-close.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 25px;
    left: 25px;
}

.temp_msg_store {
    color: #4ac0e0 !important;
    font-size: 14px !important;
    padding: 0px 35px !important;
    font-weight: 500;
    position: relative;
}

.temp_msg_store::before {
    width: 16px;
    height: 25px;
    background: url(../images/clock-close.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 4px;
}

.temp_msg_store-mob {
    color: #4ac0e0 !important;
    font-size: 14px !important;
    padding: 0px 35px !important;
    font-weight: 500;
}

.temp_msg_store-mob::before {
    width: 16px;
    height: 25px;
    background: url(../images/clock-close.png) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 142px;
    left: 32px;
}

.card-big-bx .demo-link {
    font-size: 14px;
    line-height: 22px;
    color: #1b2b81;
    font-weight: 500;
    text-decoration: underline;
}

.gm-style-iw-d {
    overflow: hidden !important;
    max-height: none !important;
    padding-right: 30px !important;
    padding-bottom: 12px !important;
}

.survey-intro-screen .survey-desp {
    display: block !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #1b2b81 !important;
    font-weight: 500 !important;
    padding: 0 !important;
    text-align: center !important;
}

.survey-intro-screen .survey-desp .sub-txt {
    font-size: 12px;
    display: block;
}

.location-white-bx .locate-me-txt {
    font-family: "Montserrat";
    font-style: normal;
    border-right: 1px solid #cfdef3;
    display: flex;
    align-items: center;
    padding: 0 0;
    /*text-decoration: underline;*/
    font-size: 14px;
    line-height: 32px;
    color: #1e0013;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-right: 15px;
    min-width: 115px;
    justify-content: center;
}

.store-listing-screen-desk.hidden {
    visibility: hidden;
    height: 0;
    padding: 0;
}

.offer-img {
    object-fit: cover !important;
}

.offer-gallery .carousel-arrow-next,
.offer-gallery .carousel-arrow-prev {
    margin-top: 26px !important;
}

.arrow-up .carousel-arrow-prev,
.arrow-up .carousel-arrow-next,
.arrow-down .carousel-arrow-prev,
.arrow-down .carousel-arrow-next {
    margin-top: -20px !important;
}

.store-site .arrow-up .carousel-arrow-prev,
.store-site .arrow-up .carousel-arrow-next,
.store-site .arrow-down .carousel-arrow-prev,
.store-site .arrow-down .carousel-arrow-next {
    margin-top: 17px !important;
}

@media (max-width: 767px) {

    .carousel-arrow-next{
        width: 30px;
        height: 30px;
        right: 6px;

    }
    .carousel-arrow-prev {
        width: 30px;
        height: 30px;
        left: -6px;
             
    }
}

.student-offer .modal-content {
    width: 400px;
    padding: 15px;
}

.student-offer .modal-dialog-centered {
    justify-content: center;
}

button:focus {
    outline: none !important;
}

.noUi-connects {
    border-radius: 3px !important;
    cursor: pointer !important;
}

@media screen and (max-width: 750px) {
    .survey-screen-bx .button-white {
        margin-top: 80px !important;
    }

    .survey-content h4 {
        font-size: 12px !important;
        padding-left: 3%;
    }
}

@media screen and (max-width: 480px) {
    .survey-screen-bx .close-listing-screen {
        width: 17px;
        height: 25px;
        background: url(../images/close-icon2.png) no-repeat left top / 100%;
        display: block;
        position: absolute;
        top: 2px;
        right: 6px;
        z-index: 9;
    }
}

@media screen and (max-width: 991px) {
    /* .btm-content .call-store-but {
        top: -46px;
    } */
}

.call-store-but1 {
    display: block;
    font-size: 18px;
    line-height: 24px;
    background: #7a126b;
    font-weight: 600;
    color: #fff;
    border-radius: 16px;
    letter-spacing: 0.2px;
    position: relative;
    padding: 16px 16px;
    margin: 0 0 8px;
}

.call-store-but1:after {
    width: 23px;
    height: 23px;
    background: url(../images/call-icon.webp) no-repeat left top / 100%;
    content: "";
    position: absolute;
    top: 50%;
    /* left: 50%; */
    right: 16px;
    transform: translate(-45%, -45%);
}

@media(min-width:320px) {

    /* header .main-nav-bx .main-nav ul li .menu-icon.warranty-icon {
        width: 31px;
        height: 39px;
        margin-bottom: 1px;
    } */
    .menu-text {
        left: 10px;
    }

    .menu-txt {
        left: 10px;
    }
}

@media(max-width:1024px) and (min-width:1000px){
    carousel{
        height: 300px !important;
    }
}

.arrowcustom {
    position: absolute !important; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%,-50%);
}
  
/* @media (device-width: 320px) and (device-height: 568px),
       (device-width: 375px) and (device-height: 667px),
       (device-width: 414px) and (device-height: 896px),
       (device-width: 428px) and (device-height: 926px),
       (device-width: 430px) and (device-height: 932px),
       (device-width: 432px) and (device-height: 938px),
       (device-width: 393px) and (device-height: 852px),
       (device-width: 395px) and (device-height: 858px),
       (device-width: 460px) and (device-height: 980px),
       (device-width: 462px) and (device-height: 984px) {
    .iframe {
        height: 300px !important;
    }
} */