/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/webfonts/Montserrat-Regular.ttf") format("truetype"),
    url("assets/webfonts/Montserrat-Black.ttf") format("truetype"),
    url("assets/webfonts/Montserrat-Bold.ttf") format("truetype"),
    url("assets/webfonts/Montserrat-ExtraBold.ttf") format("truetype"),
     url("assets/webfonts/montserrat-400.woff2") format("woff2") }

@font-face {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: 
    url("assets/webfonts/Montserrat-Bold.ttf") format("truetype")
}

@font-face {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: 
    url("assets/webfonts/Montserrat-Medium.ttf") format("truetype")
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: 
    url("assets/webfonts/Montserrat-SemiBold.ttf") format("truetype")
}

.text-red {
    color: #ca2626 !important;
}

.thumbnail-gallery-bx .gallery-thumb {
    width: 100%;
    padding: 0 60px;
    position: relative;
}

.thumbnail-gallery-bx .gallery-thumb ul {
    width: 100%;
    display: flex;
    justify-content: center;
}

.thumbnail-gallery-bx .gallery-thumb ul li {
    width: 100px;
    float: left;
    display: block;
    padding: 0 5px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 750px !important;
        margin: 1.75rem auto;
    }
}

.toast-bottom-center {
    bottom: 80% !important;
}

.toast-success {
    background-color: #514a9d !important;
}
.toast-error {
    background-color: #bd362f !important;
}
.toast-info {
    background-color: #514a9d !important;
}
.toast-warning {
    background-color: hsl(24, 95%, 50%) !important;
}

.form-sec .form-group input,
.form-sec .form-group button {
    width: 100%;
    padding: 12px;
    padding-left: 50px;
    border-radius: 5px;
    border: 1px solid #e1e1e1;
}

.form-sec .form-group input:focus {
    outline: none;
    -webkit-box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.1);
}

.form-sec .form-group input::-webkit-input-placeholder {
    color: #000;
    font-size: 16px;
}

.form-sec .form-group input::-moz-placeholder {
    color: #000;
    font-size: 16px;
}

.form-sec .form-group input:-ms-input-placeholder {
    color: #000;
    font-size: 16px;
}

.form-sec .form-group input::-ms-input-placeholder {
    color: #000;
    font-size: 16px;
}

.form-sec .form-group input::placeholder {
    color: #000;
    font-size: 16px;
}

.form-sec .form-group {
    position: relative;
    margin-bottom: 25px;
}

.form-sec .form-group .form-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 16px;
    font-size: 25px;
    color: var(--primery-color);
}

.form-sec .form-group button {
    padding: 8px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.8px;
}

.form-sec {
    max-width: 450px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 10px rgb(0 0 0 / 30%);
    box-shadow: 0px 3px 10px rgb(0 0 0 / 30%);
    padding: 50px 25px 25px;
    background: var(--white);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
}

.direction-slider .form-sec {
    margin: 10px 0;
}

.form-sec .form-group input::-webkit-input-placeholder {
    font-size: 13px;
}

.form-sec .form-group input::-moz-placeholder {
    font-size: 13px;
}

.form-sec .form-group input:-ms-input-placeholder {
    font-size: 13px;
}

.form-sec .form-group input::-ms-input-placeholder {
    font-size: 13px;
}

.form-sec .form-group input::placeholder {
    font-size: 13px;
}

.form-sec .form-group .form-icon {
    left: 12px;
    font-size: 25px;
}

.form-sec .form-group input {
    padding: 5px;
    padding-left: 50px;
    font-size: 12px;
}

.form-sec .form-group {
    margin-bottom: 15px;
}

/* .form-sec {
    padding: 25px 15px 10px;
} */

.form-sec .fs-14 {
    font-size: 14px;
}

.form-sec .fs-18 {
    font-size: 14px;
    font-weight: 500;
}

.fs-18 {
    font-size: 14px;
}

.fs-36 {
    font-size: 36px;
}

.fs-28 {
    font-size: 28px;
}

.fs-26 {
    font-size: 26px;
}

.fs-22 {
    font-size: 22px;
}

.fs-20 {
    font-size: 20px;
}

.fs-18 {
    font-size: 18px;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-14 {
    font-size: 14px;
}

.fs-12 {
    font-size: 12px;
}

.fs-10 {
    font-size: 10px;
    line-height: 1.5;
}

.icon-sec ul li a {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--gray);
    font-size: 14px;
    text-align: center;
    line-height: 14px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none;
}

.icon-sec ul li a i {
    font-size: 24px;
    color: var(--primery-color);
    margin-bottom: 8px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.icon-sec ul li a:hover,
.icon-sec ul li a:hover i {
    color: #001137;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cc-window {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	color: rgb(255, 255, 255);
	font-family: 'Poppins', sans-serif !important;
    padding: 5px !important;
}

.cc-floating .cc-compliance > .cc-btn {
    flex: none;
}

.cc-btn.cc-allow {
	background-color: rgb(0, 129, 204) !important;
	color: #fff !important;
	font-family: 'Poppins', sans-serif;
	padding: 5px !important;
	position: relative;
	left: -45%;
}

.cc-btn.cc-allow {
	padding: inherit;
	margin-left: 24px!important;
	left: 0;
  left: -72%;
} 

.cc-btn.cc-deny {
	position: absolute;
	top: -3px;
	right: -11px;
	height: auto !important;
	background-color: transparent;
}

.cc-banner .cc-message {
    flex: none;
}

.cc-message .cc-link {
    position: absolute;
    top: 59%;
    left: 9%;
}

.cc-btn.cc-allow:hover {
    background-color: rgb(0, 129, 204) !important;
}

.cc-window {
	max-width: 100% !important;
	font-family: 'Poppins', sans-serif !important;
	/* width: 208px !important;
	height: 170px; */
	display: flex;

	font-size: 11px !important;
	justify-content: space-between;
  bottom: 0;
}

.cc-compliance {
    display: flex; /* Flexbox to align buttons side by side */
    justify-content: flex-end; /* Aligns buttons to the right */
    gap: 10px; /* Space between buttons */
}

.cc-compliance .cc-btn {
    margin-left: 10px; /* Adds space between buttons */
}
@media screen and (max-width:520px) {
	.cc-btn.cc-allow {
		padding: inherit;
		margin-top: -25px;
		left: 0;
		margin-right: 20px;
    margin-top: 1px;
	}
	.cc-window {
		width: initial !important;
		height: initial !important;
		border-radius: inherit;
	}
	.cc-floating .cc-compliance>.cc-btn {
		flex: 1;
	}
	.cc-message .cc-link {
		position: inherit;
	}
  .cc-window {
    max-width: 100% !important;
    font-family: 'Poppins', sans-serif !important;
    padding: 10px !important;
    /* width: 208px !important;
    height: 170px; */
    display: flex;
    flex-direction: column;
    font-size: 11px;
    justify-content: space-evenly;
    bottom: 0;
  }
}
@media screen and (min-width:521px) {

  .cc-banner.cc-theme-edgeless .cc-message {
    margin-left: 3.5em;
}
  .cc-message .cc-link {
      position: absolute;
      top: 56%;
      left: 32% !important;
  }
  .cc-btn.cc-allow {
    padding: inherit;
    margin-left: -24px!important;
    /* position: initial;
    margin-right: 3rem;
    margin-top: -9px; */
  
  }
  .cc-window{
    width: 100%;
    /* flex-direction: row !important; */
    
  }
  /* .cc-compliance{
    display: flex;
    justify-content: flex-end;
  } */
  }
